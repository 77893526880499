<template>
  <div class="tags">
    <span class="tag is-primary is-vcentered mb-1 benefits-fade-in" v-for="(benefit, index) in benefits" :key="index">
      <i class="fas fa-check mr-1"></i>
      {{ benefit }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'SignUpBenefits.vue',
  data () {
    return {
      benefits: [
        '5 seconds to sign up',
        'No credit card needed',
        'No-time-limit free trial',
        'Unlimited players on free trial',
        'Auto-created demo games for guidance',
        'Free, live, tech support calls'
      ]
    }
  }
}
</script>

<style>
.benefits-fade-in {
  opacity: 0;
  text-align: center;
  -webkit-animation: fadeIn 3.5s;
  animation: fadeIn 3.5s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
