<template>
  <section class="hero is-white is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-10-tablet is-8-desktop is-6-widescreen">
            <div class="container has-text-centered">
              <div class="login-logo">
                <img src="https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FPlayTours-logo-horizontal.png?alt=media&token=248d7f9d-c1ca-4edc-9836-a4d3f8672c3d">
              </div>
            </div>
            <div class="box">
              <div class="container has-text-centered mt-1 mb-4">
                <div class="is-size-4 has-text-weight-bold">
                  Price Calculator
                </div>
              </div>
              <div v-if="totalPerMonth === null && !contactUs" class="container">
                <div class="mb-2 has-text-weight-semibold">
                  Will it be played individually, in teams, or mixed?
                </div>
                <div class="buttons">
                  <div
                  v-for="(teamTypeChoice, index) in ['Individually','Teams', 'Mixed']"
                  @click="teamType = teamTypeChoice"
                  :key="index"
                  class="button is-small is-rounded mb-1 mr-1"
                  :class="{'is-primary': teamType === teamTypeChoice}"
                  >
                    {{ teamTypeChoice }}
                  </div>
                </div>
                <div v-if="teamType && teamType !== 'Individually'" class="mb-5">
                  <div class="mb-2 has-text-weight-semibold">
                  How many teams in an event? {{ teamType === 'Mixed' ? '(Solo players are considered as a team with 1)' : '' }}
                  </div>
                  <input class="input is-normal" type="number" v-model="maxTeams">
                </div>
                <div v-if="teamType && teamType === 'Individually'" class="mb-5">
                  <div class="mb-2 has-text-weight-semibold">
                  How many players in an event?
                  </div>
                  <div class="buttons">
                    <div
                    v-for="(maxPlayersChoice, index) in [10, 20, 30, 40, 50, 60, 120, 'More than 120']"
                    @click="maxPlayers = maxPlayersChoice"
                    :key="index"
                    class="button is-small is-rounded mb-1 mr-1"
                    :class="{'is-primary': maxPlayers === maxPlayersChoice}"
                    >
                    {{ !isNaN(maxPlayersChoice) ? `Max ${maxPlayersChoice}` : maxPlayersChoice }}
                    </div>
                  </div>
                </div>
                <div v-if="teamType && teamType !== 'Individually' && maxTeams" class="mb-2">
                  <div class="mb-2 has-text-weight-semibold">
                    Average number of devices per team?
                  </div>
                  <div class="buttons">
                    <div
                    v-for="(maxDeviceChoice, index) in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                    @click="maxDevices = maxDeviceChoice"
                    :key="index"
                    class="button is-small is-rounded mb-1 mr-1"
                    :class="{'is-primary': maxDevices === maxDeviceChoice}"
                    >
                      {{ maxDeviceChoice }}
                    </div>
                  </div>
                </div>
                <div v-if="(teamType && teamType !== 'Individually' && maxTeams && maxDevices) || (teamType && teamType === 'Individually' && maxPlayers)" class="mb-2 mt-5">
                  <div class="mb-0 has-text-weight-semibold">
                    Max number of session(s) in a month?
                  </div>
                  <div class="mb-2 is-size-7">
                    Select "1" if it is only a one-off event
                  </div>
                  <div class="buttons">
                    <div
                    v-for="(maxSessionsChoice, index) in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                    @click="maxSessions = maxSessionsChoice"
                    :key="index"
                    class="button is-small is-rounded mb-1 mr-1"
                    :class="{'is-primary': maxSessions === maxSessionsChoice}"
                    >
                    {{ maxSessionsChoice }}
                    </div>
                  </div>
                </div>
                <div
                class="has-text-centered mt-5 pt-2 mb-3"
                v-if="maxSessions && (teamType && teamType !== 'Individually' && maxTeams && maxDevices) || (teamType && teamType === 'Individually' && maxPlayers)"
                >
                  <button @click="revealPrice()" class="button is-primary is-medium is-rounded">Reveal Price</button>
                </div>
              </div>
              <div v-else-if="totalPerMonth !== null" class="container has-text-centered mt-1">
                <div class="is-size-5 has-text-weight-bold">
                USD {{ pricePerTeamOrPlayer.toFixed(2) }} per device
                </div>
                <div class="is-size-6 mb-2">
                On the USD {{ totalPerMonth }} / month subscription
                </div>
                <div class="is-size-6 mb-2">
                Monthly subscription, cancel anytime.
                <br>
                Unlimited free trial for 2 concurrent devices.
                </div>
                <div
                class="has-text-centered mt-2 pt-2 mb-3"
                >
                  <button @click="goToSignIn()" class="button is-primary is-medium is-rounded">Register now</button>
                  <div @click="recalculate()" class="mt-2 is-size-6 has-text-weight-semibold has-text-grey is-clickable">Re-calculate</div>
                </div>
              </div>
              <div v-else-if="contactUs" class="container has-text-centered mt-1">
                <div class="is-size-5 has-text-weight-bold">
                Custom pricing required
                </div>
                <div class="is-size-6 mb-2">
                  We typically charge anywhere between 1.50-3.50 USD per device depending on volume
                </div>
                <div
                class="has-text-centered mt-2 pt-2 mb-3"
                >
                  <a href="mailto:hello@playtours.app">
                    <button class="button is-primary is-medium is-rounded">Contact us at hello@playtours.app</button>
                  </a>
                  <div @click="recalculate()" class="mt-2 is-size-6 has-text-weight-semibold has-text-grey is-clickable">Re-calculate</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import firebaseApp from '@/firebase/init'

export default {
  name: 'PriceChecker',
  data () {
    return {
      teamType: null,
      maxTeams: null,
      maxDevices: null,
      maxPlayers: null,
      maxSessions: null,
      totalPerMonth: null,
      pricePerTeamOrPlayer: null,
      contactUs: false
    }
  },
  methods: {
    recalculate () {
      this.totalPerMonth = null
      this.pricePerTeamOrPlayer = null
      this.contactUs = false
    },
    revealPrice () {
      this.$confetti.start()
      setTimeout(() => { this.$confetti.stop() }, 1500)
      let totalDevices = 0
      if (this.teamType !== 'Individually') {
        totalDevices = Number(this.maxTeams) * this.maxDevices
      } else {
        if (isNaN(this.maxPlayers)) {
          this.contactUs = true
          return
        } else {
          totalDevices = this.maxPlayers
        }
      }
      if (totalDevices <= 2) {
        this.totalPerMonth = 0
      } else if (totalDevices <= 10) {
        this.totalPerMonth = 49
      } else if (totalDevices <= 60) {
        this.totalPerMonth = 79
      } else if (totalDevices <= 120) {
        this.totalPerMonth = 159
      } else {
        this.contactUs = true
      }

      this.pricePerTeamOrPlayer = this.totalPerMonth / totalDevices / this.maxSessions
    },
    goToSignIn () {
      this.$router.push({ name: 'login' })
    },
    goToCalendly () {
      window.open('https://tidycal.com/mnyvvp3/playtours-free-solutions-meeting', 'self')
    },
    runSigninOrRegister () {
      if (this.signinOrRegister === 'signin') {
        this.signin()
      } else {
        this.register()
      }
    },
    signin () {
      this.loading = true
      firebaseApp.auth().signInWithEmailAndPassword(
        this.email,
        this.password
      ).then(cred => {
        this.loading = false
        this.email = null
        this.password = null
        this.$router.push({ name: 'home' })
      }).catch(() => {
        this.loading = false
        this.errorMessage = 'Invalid email/password.'
        setTimeout(() => {
          this.errorMessage = null
        }, 2000)
      })
    },
    register () {
      this.loading = true
      this.errorMessage = null
      this.password2 = null
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'register-root-account',
        email: this.email
      }).then(result => {
        if (!result.data.uid) {
          this.errorMessage = result.data.error
          this.loading = false
        } else {
          this.name = null
          this.email = result.data.email
          this.password = result.data.password
          this.signin()
          this.$buefy.toast.open({
            message: 'Successfully created your account! You are now logged in. Check your email to set your password.',
            type: 'is-primary',
            queue: false
          })
          this.signinOrRegister = 'signin'
        }
        this.loading = false
        return true
      }).then(() => {
        return firebaseApp.auth().sendPasswordResetEmail(this.email)
      }).catch(err => {
        this.errorMessage = err.message
        this.loading = false
        if (err.message === 'The email address is already in use by another account.') {
          this.signinOrRegister = 'signin'
        }
      })
    },
    resetPassword () {
      this.loading = true
      this.errorMessage = null
      firebaseApp.auth().sendPasswordResetEmail(this.email).then(() => {
        this.loading = false
        this.$buefy.toast.open({
          message: 'Done! Check your email. If there is no email, the email you entered not have been registered.',
          type: 'is-primary',
          queue: false
        })
      }).catch((error) => {
        this.loading = false
        this.$buefy.toast.open({
          message: 'Done! Check your email. If there is no email, the email you entered not have been registered.',
          type: 'is-primary',
          queue: false
        })
        this.errorMessage = error.message
      })
    }
  },
  computed: {
    allowSignin () {
      return (
        this.password && this.password.length > 0 &&
        this.email && this.email.length > 0
      )
    },
    allowRegister () {
      return (
        this.email && this.email.length > 0
      )
    }
  },
  mounted () {
    // const scr = document.createElement('script')
    // scr.innerHTML = "gtag('event', 'conversion', {'send_to': 'AW-10814770953'})"
    // document.body.appendChild(scr)
  }
}
</script>

<style>
.login-logo {
  max-width: 180px;
}
</style>
