<template>
  <section class="hero is-white is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-6-tablet is-6-desktop is-6-widescreen">
            <div class="container has-text-centered">
              <div class="login-logo">
                <img src="https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FPlayTours-logo-horizontal.png?alt=media&token=248d7f9d-c1ca-4edc-9836-a4d3f8672c3d">
              </div>
            </div>
            <div v-if="signinOrRegister === 'register'" class="container mb-3">
              <sign-up-benefits />
            </div>
            <div v-if="!resetPasswordScreen" class="box mb-2">
              <article v-if="false" class="message">
                <div class="message-body">
                  Registering an account creates a "root account" that can manage 1 organisation with multiple admins.
                  <br><br>
                  If you are joining an existing organisation, <b>use the email invitation from your root account admin to create an account</b>, instead of doing it here.
                </div>
              </article>
              <div class="field">
                <label for="" class="label">Email</label>
                <div class="control has-icons-left">
                  <input type="email" placeholder="e.g. bobsmith@gmail.com" class="input" v-model="email" required>
                  <span class="icon is-small is-left">
                    <i class="fa fa-envelope"></i>
                  </span>
                </div>
                <p v-if="signinOrRegister === 'register'" class="is-size-6 has-text-grey-light mt-1">Email must be valid & cannot be changed after registration. If you do not receive an email within 10 minutes of registration, please re-register with another email.</p>
              </div>
              <div v-if="signinOrRegister === 'signin'" class="field">
                <label for="" class="label">Password</label>
                <div class="control has-icons-left">
                  <input v-on:keyup.enter="runSigninOrRegister()" type="password" placeholder="*******" class="input" v-model="password" required>
                  <span class="icon is-small is-left">
                    <i class="fa fa-lock"></i>
                  </span>
                </div>
              </div>
              <div v-if="errorMessage" class="container has-text-centered is-clickable">
                <div class="is-size-6 has-text-danger">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="field">
                <button
                @click="runSigninOrRegister()"
                class="button is-fullwidth is-primary"
                v-bind:class="{'is-loading': loading}"
                :disabled="signinOrRegister === 'signin' && !allowSignin || signinOrRegister === 'register' && !allowRegister"
                >
                  {{ signinOrRegister === 'signin' ? 'Login' : 'Register' }}
                </button>
              </div>
              <div v-if="signinOrRegister === 'signin'" @click="toggleResetPasswordScreen" class="container has-text-centered is-clickable mt-5">
                <div class="is-size-6 has-text-grey">
                  I forgot my password
                </div>
              </div>
              <div @click="toggleSigninOrRegister" class="container has-text-centered is-clickable">
                <div class="is-size-6 has-text-grey">
                  {{ signinOrRegister === 'signin' ? 'Create an account instead' : 'I have an account, log in instead' }}
                </div>
              </div>
            </div>
            <div v-if="signinOrRegister === 'register'" class="box">
              <div class="level">
                <div class="level-left">
                  <div class="level-item">
                    <div class="is-size-6">
                      Want a sneak peak?
                    </div>
                  </div>
                </div>
                <div class="level-right">
                  <div class="level-item">
                    <button
                    @click="goToCalendly()"
                    class="button is-primary"
                    >
                      <i class="fas fa-calendar-check mr-3"></i>
                      Schedule a free demo
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="resetPasswordScreen" class="box">
              <article class="message">
                <div class="message-body">
                  You will receive an email with a link to reset your password. If you do not have access to your email, please email "hello@playtours.app" for assistance.
                </div>
              </article>
              <div class="field">
                <label for="" class="label">Email</label>
                <div class="control has-icons-left">
                  <input type="email" placeholder="e.g. bobsmith@gmail.com" class="input" v-model="email" required>
                  <span class="icon is-small is-left">
                    <i class="fa fa-envelope"></i>
                  </span>
                </div>
              </div>
              <div class="field">
                <button
                @click="resetPassword()"
                class="button is-fullwidth is-primary"
                v-bind:class="{'is-loading': loading}"
                >
                  Send reset password email
                </button>
              </div>
              <div @click="toggleResetPasswordScreen" class="container has-text-centered is-clickable">
                <div class="is-size-6 has-text-grey">
                  Back
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" :class="{ 'is-active': showRegisterModal }">
      <div class="modal-background"></div>
      <div class="modal-content px-4">
        <div class="card has-text-centered" style="margin: auto; max-width: 500px;">
          <div class="card-content">
            <p class="subtitle" style="margin-bottom: 35px;">
            Confirm your email address
            </p>
            <p class="title">
            {{ email ? email.toLowerCase() : email }}
            </p>
            <div>
              <button
                :class="{ 'is-loading': loading}"
                @click="showPoll()"
                class="button is-primary mr-2"
              >
                Correct
              </button>
              <button
                :class="{ 'is-loading': loading}"
                @click="showRegisterModal = false"
                class="button is-outlined is-primary"
              >
                Wrong, let me edit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" :class="{ 'is-active': showPollModal }">
      <div class="modal-background"></div>
      <div class="modal-content px-4">
        <div class="card has-text-centered" style="margin: auto; max-width: 500px;">
          <div class="card-content">
            <p class="title is-size-4">
              How did you find out about us?
            </p>
            <p class="subtitle is-size-6 mb-5">
              Your input helps us reduce our costs so that we can continue providing the <u>best price in the market.</u>
            </p>
            <div class="buttons mb-0">
              <div
              v-for="(pollChoiceOption, index) in ['Google', 'Product review website', 'Social media', 'Recommended by someone', 'Used as a player before'].sort(() => Math.random() - 0.5).concat('Others')"
              @click="pollChoice = pollChoiceOption"
              :key="index"
              class="button is-small is-rounded mb-2 mr-1"
              :class="{'is-primary': pollChoice === pollChoiceOption || (pollChoiceOption === 'Google' && (pollChoice === 'Google ads' || pollChoice === 'Google search'))}"
              >
                {{ pollChoiceOption }}
              </div>
            </div>
            <div v-if="['Google', 'Google ads', 'Google search'].includes(pollChoice)" class="mb-4 mt-3">
              <p class="subtitle is-size-6 mb-2">
                Sponsored or organic?
              </p>
              <div class="is-flex is-justify-content-center">
              <img
                @click="pollChoice = 'Google ads'"
                class="image rounded-image is-clickable mr-3"
                style="width: 45%; box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);"
                :class="{'is-selected': pollChoice === 'Google ads'}"
                src="https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FpollChoiceGoogleAds.png?alt=media&token=7e6a84b7-2659-479a-b513-72e3bca0dfa4" />
              <img
                @click="pollChoice = 'Google search'"
                class="image rounded-image is-clickable"
                style="width: 45%; box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);"
                :class="{'is-selected': pollChoice === 'Google search'}"
                src="https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FpollChoiceGoogleSearch.png?alt=media&token=ba69afa3-9cc7-495a-8867-b9df8076546a" />
              </div>
            </div>
            <div @click="pollChoice = 'Prefer not to say'" class="has-text-grey-light is-size-7 is-clickable mb-2">
              Prefer not to say
            </div>
            <div>
              <button
                :class="{ 'is-loading': loading }"
                @click="register()"
                class="button is-primary"
                :disabled="!pollChoice || (pollChoice === 'Google' && !['Google ads', 'Google search'].includes(pollChoice)) || !allowRegister"
              >
                Register
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import firebaseApp from '@/firebase/init'
import SignUpBenefits from '../components/SignUpBenefits.vue'

export default {
  components: { SignUpBenefits },
  name: 'Signin',
  data () {
    return {
      resetPasswordScreen: false,
      signinOrRegister: 'register',
      errorMessage: null,
      name: null,
      email: null,
      password: null,
      password2: null,
      pollChoice: null,
      loading: false,
      showRegisterModal: false,
      showPollModal: false
    }
  },
  methods: {
    goToCalendly () {
      window.open('https://tidycal.com/mnyvvp3/playtours-free-solutions-meeting', 'self')
    },
    toggleSigninOrRegister () {
      this.errorMessage = null
      if (this.signinOrRegister === 'signin') {
        this.signinOrRegister = 'register'
      } else {
        this.signinOrRegister = 'signin'
      }
    },
    toggleResetPasswordScreen () {
      this.errorMessage = null
      this.resetPasswordScreen = !this.resetPasswordScreen
    },
    runSigninOrRegister () {
      if (this.signinOrRegister === 'signin') {
        this.signin()
      } else {
        this.showRegisterModal = true
        this.loading = true
        setTimeout(() => {
          this.loading = false
        }, 1500)
      }
    },
    signin () {
      this.loading = true
      firebaseApp.auth().signInWithEmailAndPassword(
        this.email,
        this.password
      ).then(cred => {
        this.loading = false
        this.email = null
        this.password = null
        this.$router.push({ name: 'home' })
      }).catch(() => {
        this.loading = false
        this.errorMessage = 'Invalid email/password.'
        setTimeout(() => {
          this.errorMessage = null
        }, 2000)
      })
    },
    showPoll () {
      this.showRegisterModal = false
      this.showPollModal = true
    },
    register () {
      // this.showRegisterModal = false
      this.loading = true
      this.errorMessage = null
      this.password2 = null
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'register-root-account',
        email: this.email.toLowerCase(),
        pollChoice: this.pollChoice
      }).then(result => {
        if (!result.data.uid) {
          this.errorMessage = result.data.error
          this.loading = false
        } else {
          this.name = null
          this.email = result.data.email
          this.password = result.data.password
          this.signin()
          this.$buefy.toast.open({
            message: 'Successfully created your account! You are now logged in. Check your email to set your password.',
            type: 'is-primary',
            queue: false
          })
          this.signinOrRegister = 'signin'
        }
        this.loading = false
        return true
      }).then(() => {
        return firebaseApp.auth().sendPasswordResetEmail(this.email)
      }).catch(err => {
        this.showRegisterModal = false
        this.showPollModal = false
        this.errorMessage = err.message
        this.loading = false
        if (err.message === 'The email address is already in use by another account.') {
          this.signinOrRegister = 'signin'
        }
      })
    },
    resetPassword () {
      this.loading = true
      this.errorMessage = null
      firebaseApp.auth().sendPasswordResetEmail(this.email).then(() => {
        this.loading = false
        this.$buefy.toast.open({
          message: 'Done! Check your email. If there is no email, the email you entered not have been registered.',
          type: 'is-primary',
          queue: false
        })
      }).catch((error) => {
        this.loading = false
        this.$buefy.toast.open({
          message: 'Done! Check your email. If there is no email, the email you entered not have been registered.',
          type: 'is-primary',
          queue: false
        })
        this.errorMessage = error.message
      })
    }
  },
  computed: {
    allowSignin () {
      return (
        this.password && this.password.length > 0 &&
        this.email && this.email.length > 0
      )
    },
    allowRegister () {
      return (
        this.email && this.email.length > 0 && this.email.includes('@')
      )
    }
  },
  mounted () {
    const scr = document.createElement('script')
    scr.innerHTML = "gtag('event', 'conversion', {'send_to': 'AW-10814770953/ptIuCO2nzIcDEIme8aQo'})"
    document.body.appendChild(scr)
  }
}
</script>

<style>
.login-logo {
  max-width: 180px;
}
.is-selected {
  border: 2px solid grey;
}
</style>
